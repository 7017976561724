<template>
  <div class="flex min-h-screen flex-col">
    <NuxtLayout class="flex flex-1 flex-col" name="default">
      <template #header>
        <MinimalHeader :hidden="scrollState.isScrolledDown" />
      </template>
      <div class="flex flex-1 flex-col">
        <slot />
      </div>

      <template #footer>
        <!--No footer-->
        {{ null }}
      </template>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import MinimalHeader from '@/scopes/navigation/components/MinimalHeader.vue'
import { useScrollState } from '~/scopes/customer-care/common/composables/useScrollState'

const { scrollState } = useScrollState()
</script>
