import { reactive } from 'vue'

const scrollState = reactive({
  isScrolledDown: false,
})

export const useScrollState = () => {
  const handleScrollInElement = (event: { direction: 'up' | 'down' }) => {
    const { direction } = event

    if (direction === 'down') {
      scrollState.isScrolledDown = true
    } else {
      scrollState.isScrolledDown = false
    }
  }

  return {
    scrollState,
    handleScrollInElement,
  }
}
